<template>
    <v-app id="app">
        <Snackbar/>

        <div class="text-center py-3 py-lg-4">
            <v-img
                :src="require('../../shared/assets/logo.png')"
                :height="logoHeight"
                contain
                class="logo"
            />
            <h1 class="text-h6 text-sm-h4">
                {{ $t('$web.title') }}
            </h1>
        </div>

        <v-main id="main">
            <router-view></router-view>
        </v-main>

        <v-footer class="mt-5">
            <div class="mx-auto" style="font-size: 10pt;">
                <router-link :to="{ name: 'Index' }">{{ $t('$web.$navigation.home') }}</router-link> |
                <router-link :to="{ name: 'Imprint' }">{{ $t('$web.$navigation.imprint') }}</router-link> |
                <router-link :to="{ name: 'Privacy' }">{{ $t('$web.$navigation.privacy') }}</router-link> |
                <LanguageSwitcherInline/>
            </div>
        </v-footer>
    </v-app>
</template>

<script>
import LanguageSwitcherInline from "@/shared/components/utils/LanguageSwitcherInline";
import Snackbar from '../../shared/components/utils/Snackbar';

export default {
    components: {
        LanguageSwitcherInline,
        Snackbar,
    },
    computed: {
        logoHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '40px'
                case 'sm': return '60px'
                case 'md': return '70px'
                default: return '70px'
            }
        },
    },
};
</script>
