import moment from 'moment';

const formatDateTime = function(date, format) {
  if (!date) return null;

  format = format || 'L';

  return moment(date).locale(this.$i18n.locale).format(format);
};

export { formatDateTime };
