<template>
    <div class="content-wrapper px-4">
        <div class="text-center mb-5">
            <v-btn color="primary" to="/">{{ $t('$web.$navigation.backToHome') }}</v-btn>
        </div>

        <h2 class="mb-5">{{ $t('$imprint.title') }}</h2>

        <div>
            <h3>Verantwortlich für den Inhalt:</h3>
            <p><strong>LOGOSYS Logistik GmbH</strong><br>
                Otto-Röhm-Str. 69<br>
                D-64293 Darmstadt</p>
            <p>Tel.: <a href="tel:+49615181470">+49 6151 8147-0</a><br>
                Fax: +49 6151 8147-109<br>
                E-Mail: <a href="mailto:kontakt@logosys.de">kontakt@logosys.de</a></p>
            <p>Geschäftsführung: Hans-Jürgen Nützel, Marcel Nützel, Jürgen Reinhard<br>
                Handelsregister: HRB 18500, Amtsgericht Memmingen<br>
                Ust-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE815842614</p>
            <h3>Haftungsausschluss:</h3>
            <p>Alle Inhalte der Website <a href="https://www.logosys.de/">http://www.logosys.de</a> wurden sorgfältig recherchiert und zusammengestellt. Die Informationen auf dieser Website dienen ausschließlich der Darstellung&nbsp;der LOGOSYS Dienstleistungsgruppe&nbsp;und ihrem Serviceangebot. LOGOSYS behält sich das Recht vor, jederzeit und ohne vorherige Ankündigung, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen. Wir übernehmen jedoch keine Garantie – weder ausdrücklich noch stillschweigend – für die Vollständigkeit oder Richtigkeit der Informationen auf dieser Website.</p>
            <p>Für Links, die von dieser Website&nbsp;zu anderen Seiten im Internet führen, übernimmt die LOGOSYS keinerlei Haftung, da wir keinen Einfluss auf die Gestaltung und die Inhalte der verlinkten Seiten und deren weiterführende Links haben. Aus diesem Grunde distanziert sich LOGOSYS ausdrücklich von allen Inhalten verlinkter Seiten und macht sich ihre Inhalte nicht zu eigen.</p>
            <h3>Copyright:</h3>
            <p>Alle Rechte vorbehalten. Die hier zur Verfügung gestellten Inhalte sind nur zu Ihrer persönlichen Information bestimmt. Jedes Kopieren oder Veröffentlichen ist ohne ausdrückliche Genehmigung der LOGOSYS nicht gestattet.</p>
            <h3>Persönliche Daten:</h3>
            <p>Personenbezogene Daten werden nur dann erhoben, wenn Sie uns diese von sich aus z. B. im Rahmen einer Registrierung, durch das Ausfüllen von Formularen oder das Versenden von E-Mails zur Verfügung stellen. LOGOSYS verpflichtet sich, personenbezogene Daten vertraulich zu behandeln und nicht an Dritte weiterzugeben. Ein verantwortlicher Umgang mit Ihren Daten sowie die Beachtung der nationalen und internationalen Datenschutzvorschriften ist für uns selbstverständlich.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Impressum",
}
</script>

<style scoped>

</style>
