<template>
    <div>
        <div class="mx-5 text-center">
            <span class="text-sm-subtitle-1 text-md-h6">
                {{ $t('$welcomeWeb.subtitle') }}
            </span>
        </div>

        <v-card class="ma-5">
            <v-card-title class="text-sm-h6 text-md-h5">
                {{ $t('$welcomeWeb.$registrationFirstTime.title') }}
            </v-card-title>

            <v-card-text class="text-sm-body-2 text-md-body-1">
                {{ $t('$welcomeWeb.$registrationFirstTime.text') }}
            </v-card-text>

            <v-card-actions class="align-self-end">
                <v-spacer/>

                <v-btn
                    color="primary"
                    x-large
                    @click="$emit('input', 'signInInitial')"
                >
                    {{ $t('$crud.Proceed') }}
                    <SvgIcon type="mdi" :path="icons.mdiArrowRight"/>
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-card class="ma-5">
            <v-card-title class="text-sm-h6 text-md-h5" style="word-break: normal; ">
                {{ $t('$welcomeWeb.$registration.title') }}
            </v-card-title>

            <v-card-text class="text-sm-body-2 text-md-body-1">
                {{ $t('$welcomeWeb.$registration.text1') }}<br>
                {{ $t('$welcomeWeb.$registration.text2') }}
            </v-card-text>

            <v-card-actions>
                <v-spacer/>

                <v-btn
                    color="primary"
                    x-large
                    @click="$emit('input', 'signInWithId')"
                >
                    {{ $t('$crud.Proceed') }}
                    <SvgIcon type="mdi" :path="icons.mdiArrowRight"/>
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiArrowRight} from "@mdi/js";

export default {
    name: 'WebWelcome',
    components: {SvgIcon},
    data: () => {
        return {
            icons: {mdiArrowRight}
        }
    },
}
</script>
