<template>
    <v-dialog
        v-model="showMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                :label="label"
                :prepend-icon="icons.mdiClockTimeFourOutline"
                readonly
                :clearable="clearable"
                @click:clear.stop="handleClear"
                v-bind="attrs"
                v-on="on"
                :error-messages="errorMessages"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="showMenu"
            v-model="time"
            format="24hr"
            :allowed-minutes="allowedMinutes"
            :min="min"
            :max="max"
            full-width
            @click:minute="handleInput"
        ></v-time-picker>
    </v-dialog>
</template>

<script>
import { mdiClockTimeFourOutline } from '@mdi/js';

export default {
    props: {
        value: String,
        allowedMinutes: {
            default: () => m => m % 1 === 0
        },
        min: {
            type: String,
            default: () => '00:00'
        },
        max: {
            type: String,
            default: () => '24:00'
        },
        label: {
            type: String,
            required: false,
            default: () => ''
        },
        errorMessages: {
            type: Array,
            required: false
        },
        clearable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showMenu: false,
            timeOutput: '12:00',
            icons: {mdiClockTimeFourOutline}
        }
    },

    computed: {
        time: {
            get() {
                return this.value;
            },
            set(value) {
                this.timeOutput = value;
            }
        }
    },

    methods: {
        handleInput() {
            this.showMenu = false;
            this.$emit('input', this.timeOutput);
        },
        handleClear() {
            this.time = null;
            this.$emit('input', this.timeOutput);
        }
    },
}
</script>
