import Vue from 'vue';
import Vuex from 'vuex';

import notifications from "../../../shared/store/modules/notifications";
import makeCrudModule from "../../../shared/store/modules/crud";
import locationService from "../../../shared/services/location";
import appointment from "../../../shared/store/modules/appointment";
import spaceService from '../../../shared/services/space';
import completedtraining from "../../../shared/store/modules/completedtraining";
import spacetraining from "../../../shared/store/modules/spacetraining";
import training from "../../../shared/store/modules/training";
import hostService from "../../../shared/services/host";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    notifications,
    location: makeCrudModule({
      service: locationService
    }),
    space: makeCrudModule({
      service: spaceService
    }),
    appointment,
    completedtraining,
    spacetraining,
    training,
    host: makeCrudModule({
      service: hostService
    }),
  },
  actions: {
    resetState: function({ dispatch }) {
      // call resetState action for each module
      Object.keys(this._modulesNamespaceMap).forEach(module => {
        let action = module + 'resetState';
        // make sure that action exists for the module
        if(this._actions[action]) {
          dispatch(action).then(() => {});
        }
      });
    }
  },
  strict: process.env.NODE_ENV !== 'production'
});

export default store;
