<template>
    <div class="d-flex flex-column fill-height px-5">
        <div>
            <InputDate
                v-model="date"
                :label="$t('$registration.date')"
                :error-messages="dateErrors"
                :min-date="minDate"
                :max-date="maxDate"
            />

            <InputTime
                v-model="startTime"
                :label="$t('$registration.startTime')"
                :error-messages="startTimeErrors"
                :max="endTime"
                :allowed-minutes="allowedMinutes"
            />

            <InputTime
                v-model="endTime"
                :label="$t('$registration.endTime')"
                :min="startTime"
                :allowed-minutes="allowedMinutes"
            />

            <v-select
                v-model="location"
                :items="locations"
                item-value="@id"
                item-text="name"
                :label="$t('$registration.location')"
                :error-messages="locationErrors"

            >
                <template v-slot:item="{ item }">
                    <template>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name"/>
                            <v-list-item-subtitle v-text="item.address"/>
                        </v-list-item-content>
                    </template>
                </template>
            </v-select>

            <v-select
                v-model="space"
                :items="spaces"
                item-value="@id"
                item-text="name"
                :disabled="!location"
                :label="$t('$registration.space')"
                :error-messages="spaceErrors"
                :persistent-hint="!location"
                :hint="!location ? $t('$registration.spaceHint') : ''"
            />

            <HostSearchField
                v-model="visitAt"
            />
        </div>

        <div class="mt-auto">
            <div class="d-flex py-2">
                <v-btn @click="$emit('prev')" x-large>
                    <SvgIcon type="mdi" :path="icons.mdiArrowLeft"/>
                    {{ $t('$crud.Back') }}
                </v-btn>

                <v-spacer/>

                <v-btn color="primary" @click="handleNext" x-large>
                    {{ $t('$crud.Next') }}
                    <SvgIcon type="mdi" :path="icons.mdiArrowRight"/>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiArrowLeft, mdiArrowRight} from "@mdi/js";
import moment from 'moment';
import {validationMixin} from 'vuelidate';
import InputDate from "../../../shared/components/utils/InputDate";
import InputTime from "../../../shared/components/utils/InputTime";
import {required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import SpaceMixin from "../../../shared/mixins/SpaceMixin";
import HostSearchField from "../../../shared/components/host/HostSearchField";

export default {
    name: 'AppointmentForm',
    components: {SvgIcon, InputTime, InputDate, HostSearchField},
    mixins: [validationMixin,SpaceMixin],
    data() {
        return {
            minDate: moment().format('YYYY-MM-DD'),
            maxDate: moment().add(1, 'year').format('YYYY-MM-DD'),
            allowedMinutes: m => m % 5 === 0,
            date: null,
            startTime: null,
            endTime: null,
            icons: {mdiArrowLeft, mdiArrowRight}
        };
    },

    mounted() {
        this.fetchLocations({});
    },

    computed: {
        ...mapGetters('location', {
            locations: 'list',
            findLocation: 'find',
        }),

        ...mapFields('appointment', [
            'form.expectedStartTime',
            'form.expectedEndTime',
            'form.location',
            'form.space',
            'form.visitAt',
            'form.trainings',
            'requiredSpaceTrainings',
            'selectedSpaceId'
        ]),

        spaces() {
            return this.getSpaces(this.location) || [];
        },

        dateErrors() {
            return this.getErrorMessageReq('date');
        },

        startTimeErrors() {
            return this.getErrorMessageReq('startTime');
        },

        locationErrors() {
            return this.getErrorMessageReq('location');
        },

        spaceErrors() {
            return this.getErrorMessageReq('space');
        },
    },

    watch: {
        date() {
            this.setExpectedStartTime();
            this.setExpectedEndTime();
        },
        startTime() {
            this.setExpectedStartTime();
        },
        endTime() {
            this.setExpectedEndTime();
        }
    },

    methods: {
        handleNext() {
            this.$v.$touch();

            if(!this.$v.$invalid) {
                // // retrieve space with spaceTrainings
                this.selectedSpaceId = this.space;
                // this.retrieveSpace(this.space);
                this.$emit('next');
            }
        },

        ...mapActions('location', {
            fetchLocations: 'fetchAll',
        }),

        updateSpaces(locationId) {
            const location = this.findLocation(locationId);
            this.spaces = location.spaces;
        },

        getSpaces(locationId) {
            if(!locationId) return [];

            const location = this.findLocation(locationId);
            if(!location) return [];

            return location.spaces;
        },

        getErrorMessageReq(field) {
            const errors = [];
            if (!this.$v[field].$dirty) return errors;
            !this.$v[field].required && errors.push(this.$t('$validation.Field is required'));
            return errors;
        },

        setExpectedStartTime() {
            const date = this.date || moment().format('YYYY-MM-DD');
            const startTime = this.startTime || '12:00';
            this.expectedStartTime = moment(`${date} ${startTime}`).utc().format();
        },

        setExpectedEndTime() {
            if(this.endTime) {
                const date = this.date || moment().format('YYYY-MM-DD');
                this.expectedEndTime = moment(`${date} ${this.endTime}`).utc().format();
            } else {
                this.expectedEndTime = null;
            }
        },
    },

    validations: {
        date: {
            required,
        },
        startTime: {
            required,
        },
        endTime: {
        },
        location: {
            required,
        },
        space: {
            required,
        }
    }
};
</script>
