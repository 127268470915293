<template>
    <v-form class="d-flex flex-column fill-height px-5">
        <div style="min-width: 480px; margin: 0 auto">
            <v-text-field
                v-model="visitorId"
                :label="$t('$registration.visitorId')"
                :error-messages="visitorIdErrors"
                @keyup="sanitizeInput"
                @touchend="sanitizeInput"
                autocomplete="off"
            />
        </div>

        <v-alert
            v-if="visitorIdNotFound"
            type="error"
            border="left"
        >
            {{ $t('$error.visitorIdNotFound') }}
        </v-alert>

        <div class="mt-auto">
            <div class="d-flex py-2">
                <v-btn
                    @click="handleAbort"
                    x-large
                    ripple
                >
                    {{ $t('$crud.Abort') }}
                </v-btn>

                <v-spacer/>

                <v-btn
                    color="primary"
                    @click.prevent="handleNext"
                    x-large
                    ripple
                    type="submit"
                >
                    {{ $t('$crud.Next') }}
                    <SvgIcon type="mdi" :path="icons.mdiArrowRight"/>
                </v-btn>
            </div>
        </div>
    </v-form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapFields} from "vuex-map-fields";
import {mdiArrowLeft, mdiArrowRight} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import visitorService from "../../../shared/services/visitor";
import {mapActions} from "vuex";

export default {
    name: 'VisitorIdForm',
    components: {SvgIcon},
    mixins: [validationMixin],
    data() {
        return {
            visitorIdNotFound: false,
            icons: {mdiArrowLeft, mdiArrowRight}
        };
    },

    computed: {
        ...mapFields('appointment', [
            'form.visitorId',
            'form.space',
            'form.visitAt'
        ]),

        visitorIdErrors() {
            const errors = [];
            if (!this.$v.visitorId.$dirty) return errors;
            !this.$v.visitorId.required && errors.push(this.$t('$validation.Field is required'));
            (!this.$v.visitorId.minLength || !this.$v.visitorId.maxLength) && errors.push(this.$t('$validation.This value should have exactly {limit} characters', {limit: 6}));
            return errors;
        },
    },

    methods: {
        ...mapActions('completedtraining', ['setTrainings']),

        handleAbort() {
            this.$emit('abort');
        },

        handleNext() {
            this.$v.$touch();

            if(!this.$v.$invalid) {
                this.visitorIdNotFound = false;

                visitorService
                    .registration({
                        visitorId: this.visitorId
                    })
                    .then((data) => {
                        this.setTrainings(data['completedTrainings'])

                        this.$emit('next');
                    })
                    .catch(() => {
                        this.visitorIdNotFound = true;
                    });
            }
        },

        sanitizeInput() {
            if(!this.visitorId) return;

            // remove non alphanumerical characters and make uppercase
            this.visitorId = this.visitorId
                .replace(/\s*/gi, '')
                .substr(0, 6)
                .replace(/[^0-9a-zA-Z]/gi, '')
                .toUpperCase();
        },
    },

    validations: {
        visitorId: {
            required,
            minLength: minLength(6),
            maxLength: maxLength(6)
        },
    }
};
</script>
