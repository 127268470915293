<template>
    <span>
        <a href="#" @click="switchToLanguage('de')" v-if="$i18n.locale === 'en'">
            <FlagDE style="width: 1rem"/>
            Deutsch
        </a>
        <a href="#" @click="switchToLanguage('en')" v-if="$i18n.locale === 'de'">
            <FlagEN style="width: 1rem"/>
            English
        </a>
    </span>
</template>

<script>
import FlagDE from "@/shared/components/utils/FlagDE";
import FlagEN from "@/shared/components/utils/FlagEN";

export default {
    name: 'LanguageSwitcherInline',
    components: {
        FlagEN,
        FlagDE,
    },
    methods: {
        switchToLanguage(lang) {
            this.$i18n.locale = lang;
        }
    },
}
</script>
