<template>
    <v-dialog
        v-model="showMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="290px"
        max-width="600px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="date"
                :label="label"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-on="on"
                :clearable="clearable"
                @click:clear.stop="handleClear"
                :error-messages="errorMessages"
            ></v-text-field>
        </template>
        <v-date-picker
            ref="picker"
            v-model="date"
            @input="handleInput"
            :year-icon="icons.mdiCalendarSearch"
            :locale="locale"
            :min="minDate"
            :max="maxDate"
            width="auto"
        ></v-date-picker>
    </v-dialog>
</template>

<script>
import {formatDateTime} from '../../utils/dates';

import {
    mdiCalendar,
    mdiCalendarSearch
} from '@mdi/js';

export default {
    props: {
        label: {
            type: String,
            required: false,
            default: () => ''
        },
        value: String,
        errorMessages: {
            type: Array,
            required: false
        },
        minDate: {
            type: String,
            required: false,
            default: () => null
        },
        maxDate: {
            type: String,
            required: false,
            default: () => null
        },
        activePickerYear: {
            type: Boolean,
            required: false,
            default: () => false
        },
        clearable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dateOutput: null,
            showMenu: false,
            locale: process.env.VUE_APP_BCP47_LANG_TAG || 'de-de',
            icons: {
                mdiCalendar,
                mdiCalendarSearch
            }
        }
    },

    computed: {
        date: {
            get() {
                return this.value ? this.formatDateTime(this.value, 'YYYY-MM-DD') : undefined;
            },
            set(value) {
                this.dateOutput = value;
            }
        },
    },

    watch: {
        showMenu (val) {
            this.activePickerYear && val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));

            // handle closing date-picker menu as blur and emit blur event accordingly
            !val && this.$emit('blur', this.dateOutput);
        },
    },

    methods: {
        formatDateTime,
        handleInput() {
            this.showMenu = false;
            this.$emit('input', this.dateOutput);
        },
        handleClear() {
            this.date = null;
            this.$emit('input', this.dateOutput);
        }
    }
}
</script>

<style>
.v-picker {
    font-size: 1.125rem !important;
}
.v-date-picker-table .v-btn {
    font-size: inherit !important;
}
</style>
