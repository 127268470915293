<template>
    <div>
        <v-card class="pa-4">
            <div>
                <v-alert type="success" dense border="left">
                    <b>{{ $t('$completedWeb.success.title') }}</b> <br>
                    {{ $t('$completedWeb.success.text') }}
                </v-alert>

                <v-alert type="info" dense border="left">
                    <b>{{ $t('$completedWeb.info1.title') }}</b>
                    <br>
                    <small>{{ $t('$completedWeb.info1.text') }}</small>
                </v-alert>
                <div class="mb-3 text-center">
                    <div class="headline headline-2 mb-3">
                        {{ $t('$registration.visitorId') }}
                    </div>
                    <div class="d-flex justify-center align-center">
                        <div class="mr-10">
                            <div class="visitor-id my-5" v-if="data.visitorId">
                                {{ data.visitorId.toString().substr(0,3) }} {{ data.visitorId.toString().substr(3) }}
                            </div>
                        </div>
                        <div>
                            <img alt="" :src="data.qrCode" />
                        </div>
                    </div>
                </div>
                <v-alert type="info" dense border="left">
                    <b>{{ $t('$completedWeb.info2.title') }}</b>
                    <br>
                    <small>{{ $t('$completedWeb.info1.title') }}</small>
                </v-alert>

                <AnmerkungRisikobeurteilung/>

                <div>
                    <div class="text-center pt-2 pb-5">
                        <v-btn
                            color="primary"
                            @click="$emit('abort')"
                            x-large
                            ripple
                        >
                            <SvgIcon type="mdi" :path="icons.mdiHome" class="mr-2"/>
                            {{ $t('$web.$navigation.backToHome') }}
                        </v-btn>
                    </div>
                </div>

                <div>
                    <h4>{{ $t('$training.completedTrainings') }}</h4>

                    <CompletedTrainings :completed-trainings="data.completedTrainings"/>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiHome} from "@mdi/js";
import AnmerkungRisikobeurteilung from "../../../shared/components/training/AnmerkungRisikobeurteilung";
import CompletedTrainings from "../../../shared/components/training/CompletedTrainings";

export default {
    name: 'WebCompleted',
    components: {CompletedTrainings, AnmerkungRisikobeurteilung, SvgIcon},
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            icons: {mdiHome}
        }
    },
}
</script>
