<template>
    <div class="content-wrapper d-flex flex-column align-stretch" style="height: 100%; min-height: 480px">
        <WebWelcome @input="handleWelcomeInput" v-if="phase === 'welcome'"/>

        <v-stepper
            v-if="phase === 'signIn'"
            v-model="step"
            alt-labels
            class="d-flex flex-column flex-grow-1"
        >
            <v-stepper-header>
                <v-stepper-step step="1" :complete="step > 1" :color="step > 1 ? 'success' : 'primary'">
                    {{ $t('$web.stepper.contactDetails') }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" :complete="step > 2" :color="step > 2 ? 'success' : 'primary'">
                    {{ $t('$web.stepper.visit') }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" :complete="step > 3" :color="step > 3 ? 'success' : 'primary'">
                    {{ $t('$web.stepper.training') }}
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items
                class="stepper-items flex-grow-1"
            >
                <v-stepper-content step="1">
                    <VisitorIdForm
                        v-if="mode === 'visitorId'"
                        @abort="handleAbort"
                        @next="handleNext"
                    />

                    <ContactDetailsForm
                        v-else
                        @abort="handleAbort"
                        @next="handleNext"
                    />
                </v-stepper-content>

                <v-stepper-content step="2">
                    <AppointmentForm
                        :location="null"
                        @prev="handlePrev"
                        @next="handleNext"
                    />
                </v-stepper-content>

                <v-stepper-content step="3">
                    <Training
                        v-if="step === 3"
                        @prev="handlePrev"
                        @next="handleNext"
                    />
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <WebCompleted :data="visitorData" v-if="phase === 'completed'" @abort="handleAbort"/>
    </div>
</template>

<script>
import WebWelcome from "../components/Welcome";
import ContactDetailsForm from "../../../shared/components/signin/ContactDetailsForm";
import AppointmentForm from "../components/AppointmentForm";
import Training from "../../../shared/components/training/Training";
import WebCompleted from "../components/Completed";
import ScrollTopMixin from "../../../shared/mixins/ScrollTopMixin";
import VisitorIdForm from "../components/VisitorIdForm";
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";

export default {
    name: 'Index',
    components: {
        WebWelcome,
        ContactDetailsForm,
        VisitorIdForm,
        AppointmentForm,
        Training,
        WebCompleted,
    },
    mixins: [ScrollTopMixin],
    data() {
        return {
            mode: 'initial',
            phase: 'welcome',
            step: 1,
            stepStart: 0,
            stepEnd: 3,
            visitorData: {},
        }
    },

    computed: {
        ...mapFields('appointment', ['form', 'requiredSpaceTrainings', 'created'])
    },

    methods: {
        ...mapActions('appointment', {
            submitForm: 'registration',
            resetAppointment: 'resetState',
        }),

        ...mapActions('completedtraining', {
            resetCompletedTraining: 'resetState',
        }),

        handleWelcomeInput(action) {
            switch(action) {
                case 'signInInitial':
                    this.mode = 'initial';
                    this.phase = 'signIn';
                    this.step = 1;
                    break;
                case 'signInWithId':
                    this.mode = 'visitorId';
                    this.phase = 'signIn';
                    this.step = 1;
                    break;
            }
        },
        handleAbort() {
            this.resetAppointment();
            this.resetCompletedTraining();
            this.visitorData = {};
            this.phase = 'welcome';
            this.step = 1;
        },
        handlePrev() {
            this.step = Math.max(this.stepStart, this.step - 1);
            this.scrollToTop();
        },
        handleNext() {
            if(this.step === this.stepEnd) {
                this.submitForm(this.form);
            } else {
                this.step = Math.min(this.stepEnd, this.step + 1);
            }
            this.scrollToTop();
        },
    },

    watch: {
        created(created) {
            if(!created) {
                return;
            }

            this.visitorData = { ...created };

            this.phase = 'completed';

            this.resetAppointment();
            this.resetCompletedTraining();
        }
    }
}
</script>
