import Vue from 'vue'
import App from './App.vue'
import vuetify from '../../shared/plugins/vuetify';
import http from "../../shared/plugins/http";
import i18n from '../../shared/i18n';

import store from './store';
import router from './router';

import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss';
import '../../shared/assets/scss/common.scss';

Vue.config.productionTip = false

new Vue({
  i18n,
  http,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
