<template>
    <div class="content-wrapper px-4">
        <div class="text-center mb-5">
            <v-btn color="primary" to="/">{{ $t('$web.$navigation.backToHome') }}</v-btn>
        </div>

        <h2 class="mb-5">{{ $t('$privacy.title') }}</h2>

        <PrivacyProtectionContent/>
    </div>
</template>

<script>
import PrivacyProtectionContent from "../../../shared/components/privacy/PrivacyProtectionContent";
export default {
    name: "Privacy",
    components: {PrivacyProtectionContent}
}
</script>
