import Vue from 'vue';
import Router from 'vue-router';

import Index from '../views/Index'
import errorRoutes from '../../../shared/router/error';
import Imprint from "../views/Imprint";
import Privacy from "../views/Privacy";

export const routes = [
  // error pages
  errorRoutes,

  // components
  {
    path: '/',
    name: 'Index',
    component: Index,
  },

  {
    path: '/impressum',
    name: 'Imprint',
    component: Imprint,
  },

  {
    path: '/datenschutz',
    name: 'Privacy',
    component: Privacy,
  },

  // otherwise redirect to home
  {
    path: '*',
    redirect: '/'
  }
];

Vue.use(Router);

Vue.router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default Vue.router;
